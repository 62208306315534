<template>
  <v-navigation-drawer v-model="drawer" app color="white">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title text-center">
          {{ appName }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-avatar :color="user.avatar ? '' : 'blue'">
        <span v-if="!user.avatar" class="white--text headline">{{
          userInitials
        }}</span>
        <v-img :src="user.avatar" v-if="user.avatar"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="title"> {{ user.name }} </v-list-item-title>
        <v-list-item-subtitle
          v-if="userMenu.find((element) => element == 'profile')"
        >
          <router-link to="/profile">{{ labels.edit_profile }}</router-link>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense nav>
      <v-list-item
        v-for="item in menu"
        :key="item.title"
        link
        :to="item.to"
        :class="
          userMenu.find((element) => element == item.entity) ? '' : 'd-none'
        "
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group
        no-action
        :expand="false"
        prepend-icon="mdi-shield-account"
        v-if="user.admin"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ labels.admin_menu }}</v-list-item-title>
        </template>

        <v-list-item
          v-for="item in menuAdmin"
          :key="item.title"
          link
          :to="item.to"
          :class="
            userMenu.find((element) => element == item.entity) ? '' : 'd-none'
          "
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block @click="logout">
          <v-icon left color="red"> mdi-power </v-icon> Cerrar sesion
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "CoreDrawer",
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      loading: false,
      group: null,
    };
  },
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    labels: {
      get() {
        return this.$store.getters["labels"]("menu");
      },
    },
    userMenu: {
      get() {
        return this.$store.getters["auth/userMenu"];
      },
    },
    user: {
      get() {
        return this.$store.state.auth.user;
      },
    },
    userInitials: {
      get() {
        return this.$store.getters["auth/userInitials"];
      },
    },
    menuAdmin: {
      get() {
        return this.$store.getters["admin/menu"];
      },
    },
    menu: {
      get() {
        return this.$store.getters["app/menu"];
      },
    },
  },

  methods: {
    setDrawer(drawer) {
      this.$store.commit("setDrawer", drawer);
    },
    logout() {
      this.$store.dispatch("auth/logout", {});
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
